
.CREATE {
    background-color: #00bb00;
    padding: 8px 16px;
    border-radius: 8px;
}

.Tournaments {
    display: flex;
    flex-wrap: wrap;
    padding: 32px;
}
.Project h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

h1 {
    padding: 16px;
    margin: 0;
}

.Project {
    width: 400px;
    aspect-ratio: 16/9;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
    margin: 8px;
}

.Project h2 {
    position: absolute;
    bottom: 4px;
    color: white;
    margin: 0;
    left: 8px;
}

@media only screen and (max-width: 1050px) and (min-width: 768px) {
    .BigPage {
        width: 100vw;

    }

    .Project {
        width: 40vw;
        z-index: 1;
    }

    .Tournaments {
        justify-content: center;
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .Project {
        width: 90vw;
        z-index: -1;
    }

    .Tournaments {
        justify-content: center;
        padding: 0;
    }
    h1{
        width: 100%;
        padding: 0;
    }
    h2{
        font-size: 32px;
        padding-left: 4px;
    }
}