
/* Algemene stijlen */


.Poules {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.Poule {
    background-color: rgb(201, 201, 201);
    width: 90%;
    border-radius: 16px;
    margin-bottom: 16px;
    margin-left: 5%;
}
.Poule h3 {
    padding: 16px;
    font-size: 24px;
}
.POULE_TEAMINFO {
    display: flex;
    justify-content: space-between;
    padding: 2px 16px;
    
}
table{
    
    width: 100%;

}
tr{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    text-align: center;
}
td, th{
    width: 16%;
}
.BigPage{
    width: calc(100vw - 64px);
    padding: 32px;
    margin: 0;
    overflow-x: scroll;
}

/* Mediaquery voor schermen groter dan 1050px */
@media only screen and (min-width: 1050px) {

    .Poules {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .BigBracket{
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        scale: 80%;
        transform-origin: top left;
        background-color: rgb(201, 201, 201);
        padding: 32px;
        border-radius: 32px;
    }
    .Round{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: inherit;
        width: 30%;
    }
}
@media only screen and (max-width: 1050px) {
    td, th{
        width: 64%;
    }
    .Poule{
        overflow: scroll;
    }
    
}