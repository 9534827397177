
.Teams {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

}
.PageCenter{
    width: calc(100vw - 64px);
    padding: 32px;

}


.Team {
    background-color: #001c1a;
    border-radius: 16px;
    z-index: 1;
    margin: 8px;
    position: relative;
}

.TeamNaam {
    
    color: white;
    padding: 16px;
    padding-bottom: 0;
    margin: 0;
}

.Speler {
    
    display: inline-block;
    color: white;
    padding: 8px 16px;
    margin: 0;
}

.TeamLogo {
    height: 64px;
    position: absolute;
    top: 16px;
    right: 16px;
}

h2 {
    margin: 16px;
}
.Participants{
    padding-bottom: 16px;
}

.Page {
    margin-bottom: 32px;
}

@media only screen and (max-width: 767px) {
    .Team {
        width: 100%;
    }
}

@media only screen and (min-width: 767px) {
    .Team {
        width: 40%;
    }
}