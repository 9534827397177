
.TeamMember{
    margin-left: 32px;
    font-size: 24px ;
}
.GameID{
    font-size: 16px;
    margin-left: 48px;
    color: gray
}

@media only screen and (max-width: 1050px) {
    .LEFT {
        margin: 16px;
    }
    .RIGHT {
        margin: 16px;
    }
}

@media only screen and (min-width: 1050px) {
    .Page {
        display: flex;
    }

    .LEFT {
        width: 66%;
        padding: 32px;
    }

    .RIGHT {
        width: 33%;
        padding: 32px;
    }


}