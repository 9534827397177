
* {
    margin: 0;
}


.TEAMNAME, .SCORE {
    
}

@media only screen and (max-width: 1050px) {
    .LEFT {
        margin: 16px;
    }

    .Description {
        margin-bottom: 16px;
    }

    .RIGHT {
        margin: 16px;
    }
}

@media only screen and (min-width: 1050px) {
    .Page {
        display: flex;
    }

    h2 {
        font-size: 32px;
    }

    .LEFT {
        width: 66vw;
        padding: 32px;
    }

    .RIGHT {
        width: 33vw;
        padding: 32px;
    }

    .Description {
        margin-bottom: 32px;
    }


}