.Page{
    display: flex;
    width: 100vw;
    margin: 0;
    padding: 0;
}
.Right{
    width: 30%;
    position: sticky;
    min-height: calc(100vh - 80px);
    height: 100%;
    
    background-color: lightgray;
    margin: 0;
    padding: 0;
}
.Right h3{
    padding: 16px;
    font-size: 32px;
}
.Right input, .Right textarea{
    width: 90%;
    margin: 8px;
    background-color: white;
    border: 0;
    height: 40px;
    font-size: 20px;
    border-radius: 4px;
    resize: none;
}
.Right button{
    width: 90%;
    margin: 4px;
    padding: 4px;
    padding-left: 16px;
    background-color: #00D130;
    border: 0;
    height: 40px;
    font-size: 20px;
    border-radius: 4px;
    resize: none;
}
.Right textarea{
    height: 160px;
}
.Right form{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.Left{
    width: 70%;
}