
.Poules,
.Brackets {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.SmallPoule,
.Bracket {
    background-color: rgb(201, 201, 201);
    border-radius: 16px;
    width: 90%;
    margin-bottom: 16px;
    padding-bottom: 16px;
}

.SmallPoule h3,
.Bracket h3 {
    padding: 16px;
    padding-bottom: 0;
    font-size: 24px;
    margin: 0;
}

.BRACKET_TEAMS {
    
    padding: 2px 16px;
}

.POULE_TEAMINFO {
    display: flex;
    justify-content: space-between;
    padding: 2px 16px;
    
}
a{
    text-decoration: none;
    color: black;
}

@media only screen and (min-width: 769px) {

    .Poules,
    .Brackets {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1%;
    }

    .SmallPoule,
    .Bracket {
        width: 48%;
    }
}