.UPCOMING_MATCH {
    background-color: #001c1a;
    color: white;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
}

.UPCOMING_MATCH_PARTICIPANTS {
    justify-content: center;
}

.UPCOMING_MATCH * {
    margin: 0;
}

.UPCOMING_MATCH_PARTICIPANTS, .UPCOMING_MATCH_INFORMATION {
    display: flex;
    
    gap: 8px;
}

.SCORE {
    padding: 8px 24px;
    border-radius: 8px;
}

.SCORE#WON {
    background-color: #00d439;
}

.SCORE#LOST {
    background-color: #ff0000;
}

.SCORE#TBD {
    background-color: #ffae00;
}

.UPCOMING_MATCH_INFORMATION {
    justify-content: space-between;
    padding: 16px;
}

.UPCOMING_MATCH_INFORMATION p {
    margin: 0;
}

.FINISHED_MATCH_SCORE {
    display: flex;
    justify-content: space-between;
    
    margin: 16px;
}

.STREAM {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 32px;
}

.UPCOMING_MATCH h3 {
    padding: 16px;

}

.UPCOMING_MATCH {
    margin: 16px;
    z-index: 1;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .UPCOMING_MATCHES{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }
    .UPCOMING_MATCH{
        width: 45%;
        flex-basis: 45%;
    }
}